import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import type { BreadcrumbContent } from "../types";
import { ContentfulLink } from "./link";

type Props = {
  content: BreadcrumbContent;
};

const Breadcrumb = ({ content }: Props) => {
  const links = content.fields.links;
  const inspectorProps = useContentfulInspectorMode();

  return (
    <nav aria-label="Breadcrumb">
      <ol className="inline-flex flex-wrap items-center text-xs font-bold text-brand-primary-black lg:text-sm">
        {links.map(link => (
          <li key={link.sys.id} className="inline-flex shrink-0 items-center">
            <ContentfulLink content={link} className="underline" />
            <span className="mx-2">/</span>
          </li>
        ))}
        <li
          key={content.fields.name}
          className="shrink-0"
          {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
        >
          <span className="font-normal capitalize">{content.fields.name}</span>
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;
