// PromoSticker.tsx
// Red Trapezoid for use in banners
import type React from "react";

interface PromoStickerProps {
  content: string;
}

const PromoSticker: React.FC<PromoStickerProps> = ({ content }) => {
  return (
    <div className="promo-sticker-wrapper relative mt-4  flex items-center justify-center">
      <div className="background-wrapper h-[66px] w-[184px] lg:h-[122px] lg:w-[344px] xl:h-[180px] xl:w-[505px]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="184"
          height="66"
          fill="none"
          viewBox="0 0 184 66"
          className="absolute inset-0 h-full w-full"
          style={{
            filter: "drop-shadow(rgba(0, 0, 0, 0.5) 0px 16px 16px)",
          }}
        >
          <defs>
            <filter
              id="dropShadow"
              x="-20%"
              y="-20%"
              width="150%"
              height="150%"
            >
              <feDropShadow
                dx="2"
                dy="2"
                stdDeviation="3"
                floodColor="rgba(0, 0, 0, 0.25)"
              />
            </filter>
          </defs>
          <path
            fill="#D81A21"
            stroke="#FFFFFF"
            strokeWidth="2"
            d="M9.027.947H181.18l2.797 65.014H.381L9.027.947Z"
            filter="url(#dropShadow)"
          />
        </svg>
      </div>
      <p className="absolute text-center font-roboto text-[44px] font-black uppercase italic leading-none text-white  lg:text-[82px] xl:text-[120px]">
        {content}
      </p>
    </div>
  );
};

export default PromoSticker;
