import type { LinkProps } from "@remix-run/react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui/accordion";
import { cn } from "~/lib/ui";

import type { LinkGroupContent } from "../types";
import { ContentfulLink } from "./link";

type Props = {
  content: LinkGroupContent;
  containerStyles?: string;
  linkStyles?: string;
  prefetch?: LinkProps["prefetch"];
  titleClassName?: string;
  hamburgerMenu?: boolean;
};

export const LinkGroup = ({
  content,
  prefetch,
  titleClassName,
  hamburgerMenu,
}: Props) => {
  return (
    <>
      <div className="flex h-10 items-center">
        <p
          className={cn(
            hamburgerMenu
              ? "font-roboto text-base uppercase text-black"
              : "text-sm font-bold text-neutral-2",
            titleClassName,
          )}
        >
          {content.fields.title}
        </p>
      </div>
      <ul>
        {content.fields.links.map(field => (
          <li
            key={field.sys.id}
            className="group relative w-full before:absolute  before:-left-8 before:-right-8 before:bottom-0 before:top-0  before:bg-gray-100 before:opacity-0 before:transition-opacity before:duration-200 hover:before:opacity-100"
          >
            <ContentfulLink
              content={field}
              prefetch={prefetch}
              className="relative z-10 block w-full py-2 text-brand-primary-black no-underline hover:no-underline"
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export const ExpandableLinkGroup = ({ content, prefetch }: Props) => {
  return (
    <>
      <div className="hidden lg:block">
        <p className="mb-4 font-bold capitalize text-white">
          {content.fields.title}
        </p>

        {/* Default ul list (for screens lg and above) */}
        <ul className="hidden lg:block ">
          {content.fields.links.map(field => (
            <li key={field.sys.id}>
              <ContentfulLink
                content={field}
                prefetch={prefetch}
                className="mb-1 text-2xs font-bold tracking-wide text-white no-underline hover:text-gray-300  hover:no-underline"
              />
            </li>
          ))}
        </ul>
      </div>

      {/* ul list for small screens (for screens below md) */}
      <div className="block border-b border-white lg:hidden">
        <Accordion type="single" collapsible>
          <AccordionItem value="shop">
            <AccordionTrigger className="h-12 border-b-0 px-0 py-0 text-xs font-semibold text-white data-[state=open]:bg-inherit">
              {content.fields.title}
            </AccordionTrigger>
            <AccordionContent>
              <ul className="flex flex-col gap-3 pb-5">
                {content.fields.links.map(field => (
                  <li key={field.sys.id}>
                    <ContentfulLink
                      content={field}
                      prefetch={prefetch}
                      className="text-xs text-neutral-6 no-underline hover:text-neutral-4 hover:no-underline"
                    />
                  </li>
                ))}
              </ul>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export const LinkGroupRow = ({
  content,
  containerStyles,
  linkStyles,
  prefetch,
}: Props) => {
  return (
    <ul className={containerStyles}>
      {content.fields.links.map(field => (
        <li key={field.sys.id}>
          <ContentfulLink
            content={field}
            className={linkStyles}
            prefetch={prefetch}
          />
        </li>
      ))}
    </ul>
  );
};
